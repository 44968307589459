<template>
  <div
    class="user-home-container"
    :style="{
      'min-height': `${$screenHeight}px`,
    }"
  >
    <!-- 我的基本信息 -->
    <user-base-info :info="info"></user-base-info>
    <!-- 基础操作 -->
    <div class="user-base-actions">
      <row-info-bar
        v-for="(bar, index) in baseActions"
        :key="index"
        :title="bar.title"
        :link="bar.link"
        :val="bar.val"
        :is-offset="bar.isOffset"
      ></row-info-bar>
    </div>
    <!-- 其他描述 -->
    <div class="other-describe-container">
      <p class="produce-text"><span>CROSSiDEA如是灵感</span> 出品</p>
      <img src="@/assets/images/qrex.png" alt="" />
      <p v-if="info && Number(info.info.subscribe) === 0" class="hint-text">
        长按关注公众号，接收互动消息
      </p>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import userBaseInfo from './_base-info.vue';
import rowInfoBar from '../common/_row-info-bar.vue';

export default {
  name: 'page-user-home',
  components: {
    userBaseInfo,
    rowInfoBar,
  },
  data() {
    return {
      baseActions: [
        {
          title: '我的主页',
          link: '/preview',
        },
        {
          title: '全部时光',
          link: '/my/timeline?type=all',
          val: 0,
        },
        {
          title: '申请管理',
          link: '/my/apply-manage',
        },
        {
          title: '邀请亲友',
          link: '/my/code',
        },
        {
          title: '账号设置',
          isOffset: true,
          link: '/my/setup',
        },
        {
          title: '帮助与反馈',
          link: '/instructions',
        },
      ],
    };
  },
  computed: {
    ...mapState(['user']),
    info() {
      const data = {};
      data.info = this.user;
      return this.user ? data : null;
    },
  },
  watch: {
    info() {
      this.refreshSubscribe();
    },
  },
  mounted() {
    const that = this;
    that.$request.get('api/user/timeline/post/counts').then((res) => {
      that.baseActions[1].val = res.data.count;
    });
  },
  methods: {
    ...mapMutations(['UPDATE_USER']),
    refreshSubscribe() {
      const that = this;
      that.$request.get('api/user/account/is-subscribe').then((res) => {
        that.UPDATE_USER({
          key: 'subscribe',
          val: Number(res.data),
        });
      });
    },
  },
};
</script>

<style></style>
